
















































// CORE
import { Component, Vue } from 'vue-property-decorator'

// LIBRARIES
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { confirmed, required } from 'vee-validate/dist/rules'

// COMPONENTS
import ExpansionMessageCard from '@/components/cards/ExpansionMessageCard.vue'

extend('confirmed', {
  ...confirmed,
  message: 'Поля должны совпадать',
})

extend('required', {
  ...required,
  message: 'Это обязательное поле',
})

extend('passwordRequirements', {
  message: 'Пароль не соответствует требованиям',
  validate: (value: string) => {
    const regExpPassword = /^(?=.*[0-9])(?=.*[!@#$%^<>&*;:\\\-,.?/'|_=+)("[\]}{№])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^<>&*;:\\\-,.?/'|_=+)("[\]}{№]{10,}$/
    return !!value.toString().match(regExpPassword)
  },
})

@Component({
  components: {
    ExpansionMessageCard,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class RecoveryPasswordForm extends Vue {
  private showPassword = false

  protected form = {
    password: '',
    passwordConfirmation: '',
  }

  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.$emit('click:submit', this.form)
        }
      })
  }
}
