








import { Component, Vue } from 'vue-property-decorator'

// COMPONENTS
import DrawerHeader from '@/components/drawers/DrawerHeader.vue'
import RecoveryPasswordForm from '@/components/forms/RecoveryPasswordForm.vue'
import AuthModule from '@/store/modules/auth'
import { INewPassword } from '@/store/types'

@Component({
  components: {
    DrawerHeader,
    RecoveryPasswordForm,
  },
})
export default class PasswordRecovery extends Vue {
  private mounted () {
    AuthModule.verifyResetPasswordToken(this.$route.params.token)
      .catch(() => {
        setTimeout(() => {
          this.$router.push('/')
        }, 300)
      })
  }

  private handleSubmit (form: INewPassword) {
    AuthModule.resetPassword(form)
      .then(() => {
        setTimeout(() => {
          this.$router.push('/')
        }, 300)
      })
  }
}
